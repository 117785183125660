.unread {
    display: flex;
    position: absolute;
    top: 50%;
    right: 0px;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.status-room {
    display: inline-block;
    border-style: solid;
    border-width: 2px;
    border-radius: 20px;
    border-color: white;
    text-align: center;
    font-size: 10px;
    margin: 5px 0px 0px 0px;
    padding: 0px 10px 0px 10px;
}

.status-room.new {
    color: #ffffff;
    border-color: #ffffff;
}

.status-room.success {
    color: #28a745;
    border-color: #28a745;
}

.status-room.cancel {
    color: #dc3545;
    border-color: #dc3545;
}

.status-room.not-found {
    color: orange;
    border-color: orange;
}

.status-room.in-process {
    color: #17a2b8;
    border-color: #17a2b8;
}