.client-chat-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: -3px -3px 3px	#c5c5c5;
  margin: 6px 6px 6px 6px;
  -moz-box-shadow: -3px -3px 3px	#c5c5c5;
  -webkit-box-shadow: -3px -3px 3px	#c5c5c5;
  -o-box-shadow: -3px -3px 3px	#c5c5c5;
  border-radius: 10px;
}

.admin-contact {
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
}

.admin-contact .status.status-online {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: -1px;
  width: 6px;
  border-right: 5px solid #2ecc71;
}

.admin-contact .status.status-offline {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: -1px;
  width: 6px;
  border-right: 5px solid #e74c3c;
}

.admin-card .admin-card-active {
  z-index: 100;
  width: 98%;
  opacity: 1;
  transition: "opacity 5s",
}

.message-input input {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

button.submit {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* CHAT GUEST CSS */

#frame-guest {
  position: absolute;
  height: 90%;
  width: 100%;
  background: #e6eaea;
  border-radius: 10px;
}
.display-button-back-chatroom {
  display: none;
}
#frame-guest .content-messages {
  float: right;
  width: 60%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
#frame-guest .content-messages .contact-profile {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: #f5f5f5;
}
#frame-guest .content-messages .contact-profile img {
  width: 40px;
  border-radius: 50%;
  float: left;
  margin: 9px 12px 0 9px;
}
#frame-guest .content-messages .contact-profile p {
  float: left;
}
#frame-guest .content-messages .contact-profile .social-media {
  float: right;
}
#frame-guest .content-messages .contact-profile .social-media i {
  margin-left: 14px;
  cursor: pointer;
}
#frame-guest .content-messages .contact-profile .social-media i:nth-last-child(1) {
  margin-right: 20px;
}
#frame-guest .content-messages .contact-profile .social-media i:hover {
  color: #435f7a;
}
#frame-guest .content-messages .messages {
  height: 370px;
  overflow-y: scroll;
  overflow-x: hidden;
}
/* Guest chat box */
#frame-guest .content-messages .messages::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}
#frame-guest .content-messages .messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}
#frame-guest .content-messages .messages ul li {
  display: inline-block;
  clear: both;
  float: left;
  margin: 15px 15px 0px 0px;
  width: calc(100% - 25px);
  font-size: 0.9em;
}
#frame-guest .content-messages .messages ul li:nth-last-child(1) {
  margin-bottom: 20px;
}
#frame-guest .content-messages .messages ul li.sent img {
  margin: 6px 8px 0 0;
}
#frame-guest .content-messages .messages ul li.sent p {
  background: #435f7a;
  color: #f5f5f5;
  margin-bottom: 0;
}
#frame-guest .content-messages .messages ul li.replies img {
  float: right;
  margin: 6px 0 0 8px;
}
#frame-guest .content-messages .messages ul li.replies p {
  background: #f5f5f5;
  float: right;
  margin-bottom: 0;
}
#frame-guest .content-messages .messages ul li img {
  width: 22px;
  border-radius: 50%;
  float: left;
}
#frame-guest .content-messages .messages ul li p {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 205px;
  line-height: 130%;
}
#frame-guest .content-messages .message-input {
  /* position: absolute; */
  width: 100%;
  height: 50px;
}
#frame-guest .content-messages .message-input .wrap {
  position: relative;
}
#frame-guest .content-messages .message-input .wrap input {
  /* font-family: "proxima-nova", "Source Sans Pro", sans-serif; */
  float: left;
  border: none;
  height: 50px;
  width: calc(100% - 87px);
  padding: 11px 32px 10px 25px;
  font-size: 0.8em;
  color: #32465a;
  border-radius: 50px;
  margin-left: 13px;
}
#frame-guest .content-messages .message-input .wrap input:focus {
  outline: none;
}
#frame-guest .content-messages .message-input .wrap .attachment {
  position: absolute;
  right: 60px;
  z-index: 4;
  margin-top: 10px;
  font-size: 1.1em;
  color: #435f7a;
  opacity: 0.5;
  cursor: pointer;
}
#frame-guest .content-messages .message-input .wrap .attachment:hover {
  opacity: 1;
}
#frame-guest .content-messages .message-input .wrap button {
  /* float: right; */
  border: none;
  width: 50px;
  padding: 12px 0;
  height: 50px;
  cursor: pointer;
  background: #32465a;
  color: #f5f5f5;
  border-radius: 25px;
  margin-left: 5px;
}
#frame-guest .content-messages .message-input .wrap button:hover {
  background: #435f7a;
}
#frame-guest .content-messages .message-input .wrap button:focus {
  outline: none;
}
