.btn-nav-accordion[aria-expanded="true"] .fa-angle-left {
  transform: rotate(-90deg);
}

.fa-angle-left {
  transition: all 0.3s ease;
}

.toolbar {
  position: absolute;
  width: 100%;
  /* height: 50px; */
  background-color: rgba(255, 255, 255, 1);
}

/* CHAT CSS */

#frame-admin {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  top: 57px;
  bottom: 0;
  background: #e6eaea;
}
#frame-admin #sidepanel {
  float: left;
  position: relative;
  min-width: 280px;
  max-width: 340px;
  width: 40%;
  height: inherit;
  min-height: 100%;
  background: #2c3e50;
  color: #f5f5f5;
  overflow: hidden;
  border-radius: 10px;
}
.display-button-back-chatroom {
  display: none;
}
@media screen and (max-width: 735px) {
  #frame-admin #sidepanel {
    width: 100%;
    max-width: 734px;
  }
  .block-display-chat {
    display: block;
  }

  .none-display-chat {
    display: none;
  }
  .display-button-back-chatroom {
    display: block;
  }
  .badge-danger {
    color: #fff;
    background-color: #dc3545;
    margin-right: 10px;
  }
}
#frame-admin #sidepanel #profile {
  width: 80%;
  margin: 25px auto;
}
@media screen and (max-width: 576px) {
  #frame-admin #sidepanel #profile {
    width: 100%;
    margin: 0 auto;
    padding: 5px 0 0 0;
    background: #32465a;
  }
}
#frame-admin #sidepanel #profile.expanded .wrap {
  height: 210px;
  line-height: initial;
}
#frame-admin #sidepanel #profile.expanded .wrap p {
  margin-top: 20px;
}
#frame-admin #sidepanel #profile.expanded .wrap i.expand-button {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
#frame-admin #sidepanel #profile .wrap {
  height: 60px;
  line-height: 60px;
  overflow: hidden;
  -moz-transition: 0.3s height ease;
  -o-transition: 0.3s height ease;
  -webkit-transition: 0.3s height ease;
  transition: 0.3s height ease;
}
@media screen and (max-width: 735px) {
  #frame-admin #sidepanel #profile .wrap {
    height: 55px;
  }
}
#frame-admin #sidepanel #profile .wrap img {
  width: 50px;
  border-radius: 50%;
  padding: 3px;
  border: 2px solid #e74c3c;
  height: auto;
  float: left;
  cursor: pointer;
  -moz-transition: 0.3s border ease;
  -o-transition: 0.3s border ease;
  -webkit-transition: 0.3s border ease;
  transition: 0.3s border ease;
}
@media screen and (max-width: 735px) {
  #frame-admin #sidepanel #profile .wrap img {
    width: 40px;
    margin-left: 4px;
  }
}
#frame-admin #sidepanel #profile .wrap img.online {
  border: 2px solid #2ecc71;
}
#frame-admin #sidepanel #profile .wrap img.away {
  border: 2px solid #f1c40f;
}
#frame-admin #sidepanel #profile .wrap img.busy {
  border: 2px solid #e74c3c;
}
#frame-admin #sidepanel #profile .wrap img.offline {
  border: 2px solid #95a5a6;
}
#frame-admin #sidepanel #profile .wrap p {
  float: left;
  margin-left: 15px;
}
@media screen and (max-width: 735px) {
  #frame-admin #sidepanel #profile .wrap p {
    display: none;
  }
}
#frame-admin #sidepanel #profile .wrap i.expand-button {
  float: right;
  margin-top: 23px;
  font-size: 0.8em;
  cursor: pointer;
  color: #435f7a;
}
@media screen and (max-width: 735px) {
  #frame-admin #sidepanel #profile .wrap i.expand-button {
    display: none;
  }
}
#frame-admin #sidepanel #profile .wrap #status-options {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 150px;
  margin: 70px 0 0 0;
  border-radius: 6px;
  z-index: 99;
  line-height: initial;
  background: #435f7a;
  -moz-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
@media screen and (max-width: 735px) {
  #frame-admin #sidepanel #profile .wrap #status-options {
    width: 58px;
    margin-top: 57px;
  }
}
#frame-admin #sidepanel #profile .wrap #status-options.active {
  opacity: 1;
  visibility: visible;
  margin: 75px 0 0 0;
}
@media screen and (max-width: 735px) {
  #frame-admin #sidepanel #profile .wrap #status-options.active {
    margin-top: 62px;
  }
}
#frame-admin #sidepanel #profile .wrap #status-options:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #435f7a;
  margin: -8px 0 0 24px;
}
@media screen and (max-width: 735px) {
  #frame-admin #sidepanel #profile .wrap #status-options:before {
    margin-left: 23px;
  }
}
#frame-admin #sidepanel #profile .wrap #status-options ul {
  overflow: hidden;
  border-radius: 6px;
}
#frame-admin #sidepanel #profile .wrap #status-options ul li {
  padding: 15px 0 30px 18px;
  display: block;
  cursor: pointer;
}
@media screen and (max-width: 735px) {
  #frame-admin #sidepanel #profile .wrap #status-options ul li {
    padding: 15px 0 35px 22px;
  }
}
#frame-admin #sidepanel #profile .wrap #status-options ul li:hover {
  background: #496886;
}
#frame-admin #sidepanel #profile .wrap #status-options ul li span.status-circle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px 0 0 0;
}
@media screen and (max-width: 735px) {
  #frame-admin #sidepanel #profile .wrap #status-options ul li span.status-circle {
    width: 14px;
    height: 14px;
  }
}
#frame-admin
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li
  span.status-circle:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  margin: -3px 0 0 -3px;
  background: transparent;
  border-radius: 50%;
  z-index: 0;
}
@media screen and (max-width: 735px) {
  #frame-admin
    #sidepanel
    #profile
    .wrap
    #status-options
    ul
    li
    span.status-circle:before {
    height: 18px;
    width: 18px;
  }
}
#frame-admin #sidepanel #profile .wrap #status-options ul li p {
  padding-left: 12px;
}
@media screen and (max-width: 735px) {
  #frame-admin #sidepanel #profile .wrap #status-options ul li p {
    display: none;
  }
}
#frame-admin
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-online
  span.status-circle {
  background: #2ecc71;
}
#frame-admin
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-online.active
  span.status-circle:before {
  border: 1px solid #2ecc71;
}
#frame-admin
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-away
  span.status-circle {
  background: #f1c40f;
}
#frame-admin
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-away.active
  span.status-circle:before {
  border: 1px solid #f1c40f;
}
#frame-admin
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-busy
  span.status-circle {
  background: #e74c3c;
}
#frame-admin
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-busy.active
  span.status-circle:before {
  border: 1px solid #e74c3c;
}
#frame-admin
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-offline
  span.status-circle {
  background: #95a5a6;
}
#frame-admin
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-offline.active
  span.status-circle:before {
  border: 1px solid #95a5a6;
}
#frame-admin #sidepanel #profile .wrap #expanded {
  padding: 100px 0 0 0;
  display: block;
  line-height: initial !important;
}
#frame-admin #sidepanel #profile .wrap #expanded label {
  float: left;
  clear: both;
  margin: 0 8px 5px 0;
  padding: 5px 0;
}
#frame-admin #sidepanel #profile .wrap #expanded input {
  border: none;
  margin-bottom: 6px;
  background: #32465a;
  border-radius: 3px;
  color: #f5f5f5;
  padding: 7px;
  width: calc(100% - 43px);
}
#frame-admin #sidepanel #profile .wrap #expanded input:focus {
  outline: none;
  background: #435f7a;
}
#frame-admin #sidepanel #headtitle {
  margin-top: 3px;
  margin-left: 10px;
}
#frame-admin #sidepanel #search {
  /* border-top: 1px solid #32465a;
  border-bottom: 1px solid #32465a; */
  font-weight: 300;
  text-align: center;
  height: 65px;
}
#frame-admin #sidepanel #search label {
  position: absolute;
  margin: 14px 0 0 27px;
}
#frame-admin #sidepanel #search input {
  font-family: "proxima-nova", "Source Sans Pro", sans-serif;
  padding: 10px 0 10px 46px;
  width: 95%;
  border: none;
  background: #32465a;
  color: #f5f5f5;
  border-radius: 50px;
  margin: 5px;
}
#frame-admin #sidepanel #search input:focus {
  outline: none;
  background: #435f7a;
}
#frame-admin #sidepanel #search input::-webkit-input-placeholder {
  color: #f5f5f5;
}
#frame-admin #sidepanel #search input::-moz-placeholder {
  color: #f5f5f5;
}
#frame-admin #sidepanel #search input:-ms-input-placeholder {
  color: #f5f5f5;
}
#frame-admin #sidepanel #search input:-moz-placeholder {
  color: #f5f5f5;
}
#frame-admin #sidepanel .chat-groups {
  background: none;
  color: white;
  border: 0;
}
#frame-admin #sidepanel #contacts {
  position: absolute;
  top: 100px;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media screen and (max-width: 735px) {
  #frame-admin #sidepanel #contacts::-webkit-scrollbar {
    display: none;
  }
}
#frame-admin #sidepanel #contacts.expanded {
  height: calc(100% - 334px);
}
#frame-admin #sidepanel #contacts::-webkit-scrollbar {
  width: 8px;
  background: #2c3e50;
}
#frame-admin #sidepanel #contacts::-webkit-scrollbar-thumb {
  background-color: #243140;
}
#frame-admin #sidepanel #contacts ul li.contact {
  position: relative;
  padding: 6px 0 10px 8px;
  font-size: 0.9em;
  cursor: pointer;
}
@media screen and (max-width: 735px) {
  #frame-admin #sidepanel #contacts ul li.contact {
    /* padding: 6px 0 46px 8px; */
    padding: 6px 0 10px 8px;
  }
}
#frame-admin #sidepanel #contacts ul li.contact:hover {
  background: #32465a;
}
#frame-admin #sidepanel #contacts ul li.contact.active {
  background: #32465a;
  border-right: 5px solid #435f7a;
}
#frame-admin #sidepanel #contacts ul li.contact.active span.contact-status {
  /* border: 2px solid #32465a !important; */
}
#frame-admin #sidepanel #contacts ul li.contact .wrap {
  width: 95%;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 735px) {
  #frame-admin #sidepanel #contacts ul li.contact .wrap {
    width: 100%;
  }
}
#frame-admin #sidepanel #contacts ul li.contact .wrap span {
  position: absolute;
  left: 0;
  margin: 5px 0 0 30px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  background: #95a5a6;
}
#frame-admin #sidepanel #contacts ul li.contact .wrap span.online {
  background: #2ecc71;
}
#frame-admin #sidepanel #contacts ul li.contact .wrap span.away {
  background: #f1c40f;
}
#frame-admin #sidepanel #contacts ul li.contact .wrap span.busy {
  background: #e74c3c;
}
#frame-admin #sidepanel #contacts ul li.contact .wrap img {
  width: 40px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
}
/* @media screen and (max-width: 735px) {
  #frame-admin #sidepanel #contacts ul li.contact .wrap img {
    margin-right: 0px;
  }
} */
#frame-admin #sidepanel #contacts ul li.contact .wrap .meta {
  padding: 5px 0 0 0;
}
/* @media screen and (max-width: 735px) {
  #frame-admin #sidepanel #contacts ul li.contact .wrap .meta {
    display: none;
  }
} */
#frame-admin #sidepanel #contacts ul li.contact .wrap .meta .name {
  font-size: 14px;
  margin: 0;
  font-weight: 600;
}
#frame-admin #sidepanel #contacts ul li.contact .wrap .meta .preview {
  margin: 0;
  padding: 0 0 1px;
  font-weight: 400;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-transition: 1s all ease;
  -o-transition: 1s all ease;
  -webkit-transition: 1s all ease;
  transition: 1s all ease;
}
#frame-admin #sidepanel #contacts ul li.contact .wrap .meta .preview span {
  position: initial;
  border-radius: initial;
  background: none;
  border: none;
  padding: 0 2px 0 0;
  margin: 0 0 0 1px;
  opacity: 0.5;
}
#frame-admin #sidepanel #contacts ul li.contact .wrap .meta .bottom-line {
  background-color: white;
  opacity: 0.5;
  padding: 1px 0 0 0;
  margin-top: 10px;
}
#frame-admin #sidepanel #contacts #accordion {
  margin-left: 10px;
  margin-right: 1px;
}
#frame-admin #sidepanel #contacts #accordion ul{
  list-style-type: none;
  padding-left: 0px;
}
#frame-admin #sidepanel #bottom-bar {
  position: absolute;
  width: 100%;
  bottom: 0;
}
#frame-admin #sidepanel #bottom-bar button {
  float: left;
  border: none;
  width: 50%;
  padding: 10px 0;
  background: #32465a;
  color: #f5f5f5;
  cursor: pointer;
  font-size: 0.85em;
  font-family: "proxima-nova", "Source Sans Pro", sans-serif;
}
@media screen and (max-width: 735px) {
  #frame-admin #sidepanel #bottom-bar button {
    float: none;
    width: 100%;
    padding: 15px 0;
  }
}
#frame-admin #sidepanel #bottom-bar button:focus {
  outline: none;
}
#frame-admin #sidepanel #bottom-bar button:nth-child(1) {
  border-right: 1px solid #2c3e50;
}
@media screen and (max-width: 735px) {
  #frame-admin #sidepanel #bottom-bar button:nth-child(1) {
    border-right: none;
    border-bottom: 1px solid #2c3e50;
  }
}
#frame-admin #sidepanel #bottom-bar button:hover {
  background: #435f7a;
}
#frame-admin #sidepanel #bottom-bar button i {
  margin-right: 3px;
  font-size: 1em;
}
@media screen and (max-width: 735px) {
  #frame-admin #sidepanel #bottom-bar button i {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 735px) {
  #frame-admin #sidepanel #bottom-bar button span {
    display: none;
  }
}
#frame-admin .content-messages {
  float: right;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 735px) {
  #frame-admin .content-messages {
    width: 100%;
    min-width: 300px !important;
  }
}
@media screen and (min-width: 900px) {
  #frame-admin .content-messages {
    /* width: calc(100% - 340px); */
  }
}
#frame-admin .content-messages .contact-profile {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: #f5f5f5;
}
#frame-admin .content-messages .contact-profile img {
  width: 40px;
  border-radius: 50%;
  float: left;
  margin: 9px 12px 0 9px;
}
#frame-admin .content-messages .contact-profile p {
  float: left;
}
#frame-admin .content-messages .contact-profile .social-media {
  float: right;
}
#frame-admin .content-messages .contact-profile .social-media i {
  margin-left: 14px;
  cursor: pointer;
}
#frame-admin .content-messages .contact-profile .social-media i:nth-last-child(1) {
  margin-right: 20px;
}
#frame-admin .content-messages .contact-profile .social-media i:hover {
  color: #435f7a;
}
#frame-admin .content-messages .messages {
  position: relative;
  right: 0;
  left: 0;
  max-height: calc(100% - 100px);
  overflow-y: scroll;
  overflow-x: hidden;
}
@media screen and (max-width: 992px) {
  #frame-admin .content-messages .messages {
    max-height: calc(100% - 110px);
  }
}
@media screen and (max-width: 576px) {
  #frame-admin .content-messages .messages {
    max-height: calc(100% - 110px);
  }
}
#frame-admin .content-messages .messages::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}
#frame-admin .content-messages .messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}
#frame-admin .content-messages .messages ul li {
  display: inline-block;
  clear: both;
  float: left;
  margin: 15px 15px 0px 0px;
  width: calc(100% - 25px);
  font-size: 0.9em;
}
#frame-admin .content-messages .messages ul li:nth-last-child(1) {
  margin-bottom: 20px;
}
#frame-admin .content-messages .messages ul li.sent img {
  margin: 6px 8px 0 0;
}
#frame-admin .content-messages .messages ul li.sent p {
  background: #435f7a;
  color: #f5f5f5;
  margin-bottom: 0;
}
#frame-admin .content-messages .messages ul li.replies img {
  float: right;
  margin: 6px 0 0 8px;
}
#frame-admin .content-messages .messages ul li.replies p {
  background: #f5f5f5;
  float: right;
  margin-bottom: 0;
}
#frame-admin .content-messages .messages ul li img {
  width: 22px;
  border-radius: 50%;
  float: left;
}
#frame-admin .content-messages .messages ul li p {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 205px;
  line-height: 130%;
}
@media screen and (min-width: 735px) {
  #frame-admin .content-messages .messages ul li p {
    max-width: 300px;
  }
}
#frame-admin .content-messages .message-input {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 4px;
}
#frame-admin .content-messages .assign-room {
  position: absolute;
  display: flex;
  bottom: 0;
  width: 100%;
  justify-content: center;
  margin-bottom: 4px;
}
#frame-admin .content-messages .message-input .wrap {
  position: relative;
}
#frame-admin .content-messages .message-input .wrap input {
  /* font-family: "proxima-nova", "Source Sans Pro", sans-serif; */
  float: left;
  border: none;
  height: 50px;
  width: calc(100% - 87px);
  padding: 11px 32px 10px 25px;
  font-size: 0.8em;
  color: #32465a;
  border-radius: 50px;
  margin-left: 13px;
}
@media screen and (max-width: 735px) {
  #frame-admin .content-messages .message-input .wrap input {
    padding: 15px 32px 16px 8px;
  }
}
#frame-admin .content-messages .message-input .wrap input:focus {
  outline: none;
}
#frame-admin .content-messages .message-input .wrap .attachment {
  position: absolute;
  right: 60px;
  z-index: 4;
  margin-top: 10px;
  font-size: 1.1em;
  color: #435f7a;
  opacity: 0.5;
  cursor: pointer;
}
@media screen and (max-width: 735px) {
  #frame-admin .content-messages .message-input .wrap .attachment {
    margin-top: 17px;
    right: 65px;
  }
}
#frame-admin .content-messages .message-input .wrap .attachment:hover {
  opacity: 1;
}
#frame-admin .content-messages .message-input .wrap button {
  /* float: right; */
  border: none;
  width: 50px;
  padding: 12px 0;
  height: 50px;
  cursor: pointer;
  background: #32465a;
  color: #f5f5f5;
  border-radius: 25px;
  margin-left: 5px;
}
@media screen and (max-width: 735px) {
  #frame-admin .content-messages .message-input .wrap button {
    height: 50px;
    /* padding: 16px 0; */
  }
}
#frame-admin .content-messages .message-input .wrap button:hover {
  background: #435f7a;
}
#frame-admin .content-messages .message-input .wrap button:focus {
  outline: none;
}

.case-check-label {
  padding: 5px 8px 5px 8px;
}
.case-check-label.uncheck {
  opacity: 0.4;
  cursor: pointer;
  font-weight: normal;
}
.status-case-radio {
  display: none;
}
