.date-time-sender {
    /* position: absolute; */
    font-size: 8px;
    float: right;
    margin-right: 25px;
}

.date-time-receive {
    /* position: absolute; */
    font-size: 8px;
    float: left;
    margin-right: 1px;
}