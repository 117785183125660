.react-datepicker-popper {
    z-index: 3000;
}

.react-datepicker-wrapper{
  width: 100%;
}

.rmsc .dropdown-container{
  z-index: 999;
}

@media screen and (max-width: 992px) {
  .dash_time {
    width: 100%;
  }
}