.horizontal-scrollable {
  overflow-x: auto;
  white-space: nowrap;
}
.horizontal-scrollable > .col-lg-3 {
  display: inline-block;
  float: none;
}
.promotion-card {
  transition: transform .2s; /* Animation */
}
.promotion-card:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.promotion-active {
  background: rgb(2,0,36);
  background: linear-gradient(360deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 40%, rgba(0,212,255,1) 100%);
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2.5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2.5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}